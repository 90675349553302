<template>
  <div>
    <section class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <b-container fluid>
        <b-row>
          <b-col md="12" lg="12" xl="6">
            <div class="wrapper">
              <div class="title">
                <span>KAPMOBILE.ID</span>
              </div>

              <div class="msg">
                <span>{{ pesan }}</span>
              </div>
              <div class="form">
                <b-form-input
                  v-model="nama"
                  placeholder="Nama Lengkap"
                  class="inputs"
                  type="text"
                ></b-form-input>
                <b-form-input
                  v-model="email"
                  placeholder="Email"
                  class="inputs"
                  type="email"
                ></b-form-input>
                <b-form-input
                  v-model="noHp"
                  placeholder="Nomor Telepon"
                  class="inputs"
                  type="number"
                ></b-form-input>
                <b-form-input
                  v-model="password"
                  placeholder="Password"
                  class="inputs"
                  type="password"
                ></b-form-input>
                <div class="button-wrapper">
                  <b-button
                    :disabled="!password || loading == true"
                    @click="doRegis"
                  >
                    <span v-if="loading == false"> Sign Up</span>
                    <white-spinner v-else />
                  </b-button>

                  <div class="sign-in">
                    sudah punya akun?
                    <span class="biru" @click="() => this.$router.push('/')"
                      >sign in</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-col>

          <b-col xl="6" class="d-md-none d-lg-none d-xl-block pr-0">
            <div class="wrapper2">
              <hooper
                :itemsToShow="1.2"
                :autoPlay="true"
                :playSpeed="4000"
                :centerMode="true"
                :trimWhiteSpace="true"
                :infiniteScroll="true"
                :wheelControl="false"
              >
                <slide v-for="data in iklan" :key="data.i" :index="i">
                  <center>
                    <a :href="data.link_URL" target="blank">
                      <img
                        style="
                          border-radius: 7px;
                          max-width: 90%;
                          min-height: 90%;
                        "
                        :src="data.image"
                        alt=""
                      />
                    </a>
                  </center>
                </slide>
                <hooper-navigation
                  style="background-color: #8f8f8f !important"
                  slot="hooper-addons"
                ></hooper-navigation>
              </hooper>
            </div>
          </b-col>
        </b-row>

        <b-row class="d-xl-none mt-5">
          <b-col md="10" lg="10" offset-md="1" offset-lg="1">
            <hooper
              :itemsToShow="1.2"
              :autoPlay="true"
              :playSpeed="4000"
              :centerMode="true"
              :trimWhiteSpace="true"
              :infiniteScroll="true"
              :wheelControl="false"
            >
              <slide v-for="data in iklan" :key="data.i" :index="i">
                <center>
                  <a :href="data.link_URL" target="blank">
                    <img
                      style="
                        border-radius: 7px;
                        max-width: 90%;
                        min-height: 90%;
                      "
                      :src="data.image"
                      alt=""
                    />
                  </a>
                </center>
              </slide>
              <hooper-navigation
                style="background-color: #8f8f8f !important"
                slot="hooper-addons"
              ></hooper-navigation>
            </hooper>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="d-sm-block d-md-none d-lg-none d-xl-none">
      <b-container>
        <b-row>
          <b-col cols="12" sm="12">
            <div
              style="
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <h4 class="text-center">
                <strong>Does not support mobile version</strong>
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import axios2 from "@/ip_ads";
import axios from "@/ip_config";
import whiteSpinner from "../../components/spinner/white-spinner.vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: { whiteSpinner, Hooper, Slide, HooperNavigation },
  name: "register",
  data() {
    return {
      password: "",
      email: "",
      noHp: "",
      nama: "",
      pesan: "",
      loading: false,
      iklan: [],
    };
  },
  beforeMount() {
    axios2.get("promotion").then((res) => {
      if (res.data.meta.status == "success") {
        this.iklan = res.data.data.promotion;
      }
    });
  },
  methods: {
    doRegis() {
      let vm = this;
      vm.loading = true;

      axios
        .post("users/register", {
          password: vm.password,
          email: vm.email,
          noHp: vm.noHp,
          nama: vm.nama,
        })
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          // let x = localStorage.getItem("token");
          vm.loading = false;
          if (res.data.message == "email Sudah Terdaftar") {
            vm.pesan = res.data.message;
          } else {
            this.$router.push("/otp");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white2": #fff,
);
.d-xl-none .hooper {
  height: 400px;
}

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
  .title {
    font-family: "nunito", sans-serif;
    font-weight: 700;
    font-size: 48px;
    margin-top: 8%;
    margin-bottom: 4%;
    color: map-get($color, "blue");
  }

  .msg {
    font-family: "nunito", sans-serif;
    color: map-get($color, "red");
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  .form {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .inputs {
      height: 57px;
      font-family: "nunito", sans-serif;
      font-weight: 700;
      font-size: 16px;
      margin: 2% 0;
      border-radius: 6px;
    }
    .button-wrapper {
      width: 100%;
      margin-top: 3%;
      .btn {
        width: 100%;
        height: 57px;
        font-family: "nunito", sans-serif;
        font-weight: 700;
        text-transform: capitalize;
        font-size: 16px;
        border-radius: 6px;
        background-color: map-get($color, "blue");
        border: 1px solid map-get($color, "blue");
        box-shadow: 0px 20px 25px -10px rgba(15, 93, 210, 0.25);
      }
      .sign-in {
        font-family: "nunito", sans-serif;
        text-transform: capitalize;
        color: map-get($color, "black");
        font-weight: 300;
        font-size: 20px;
        margin-top: 8%;
        .biru {
          color: map-get($color, "blue");
          cursor: pointer;
          font-weight: 700;
          text-transform: capitalize;
        }
      }
    }
  }
}

.wrapper2 {
  background-image: url("../../assets/vector/Background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-repeat: repeat;
  // background-blend-mode: soft-light;
  background-color: #327ff2;
  .hooper {
    height: 350px;
  }
}
</style>
